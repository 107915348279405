module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ffd700","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Zetseat Church | Content Management","htmlFavicon":"C:\\Code\\kelal\\_pbk\\zetseat.church/src/assets/images/shared/icon.png","modulePath":"C:\\Code\\kelal\\_pbk\\zetseat.church/src/app/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":776},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"100","className":"autolink-headers"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zetseat Apostolic Reformation Church","short_name":"Zetseat Church","description":"Official Website of Zetseat Apostolic Reformation Church (https://zetseat.church/).","lang":"en","display":"standalone","icon":"src/assets/images/shared/icon.png","start_url":"/","background_color":"#ffd700","theme_color":"#d4af37"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    }]
